<template>
  <v-container fill-height>
    <v-row class="text-center" align="center" justify="center">
      <v-col cols="12">
      </v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to 2dm lab
        </h1>

        <h2>Scan the QR code below to start your journey</h2>
        <!-- TODO: Change value to deployed address -->
        <vue-qrcode scale="8" value="https://labs.2dm.digital/new-session" />
<!--        <h1>{{ screen[0].id }}</h1>-->
<!--        <h1 v-if="screen&&screen[0]['inProgress']">{{ screen[0] }}</h1>-->
<!--        <h1 v-else> not </h1>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import { db } from '../../db';

export default {
  name: 'HelloWorld',
  components: {
    VueQrcode,
  },
  data: () => ({
    demoList: [],
    screen: null,
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
  }),
  firestore: {
    demoList: db.collection('demo'),
    screen: db.collection('screen').where('screenId', '==', 1),
  },
};
</script>
