<template>
  <v-app>
    <transition name="fade">
      <div v-if="show" style="height: 100vh; width: 100vw;
      background: black; display: flex; align-items: center;
      padding: 5%">
        <img src="./assets/image/logo_text.png" />
      </div>
    </transition>
    <div v-if="faded">
      <v-app-bar
        app
        color="primary"
        dark
      >
        <div class="d-flex align-center">
          Creative Lab
        </div>
        <v-spacer></v-spacer>
        <img src="./assets/image/logo_text.png" height="100%" />
      </v-app-bar>

      <v-main>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    show: true,
    faded: false,
  }),
  mounted() {
    setTimeout(() => {
      this.show = false;
      setTimeout(() => {
        this.faded = true;
      }, 1000);
    }, 1000);
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
